<template>
  <teleport to="#modals">
    <div class="background">
        <div class="modal">
          <ButtonIcon @click="$emit('close')" class="close">
              <template #icon>
                  <Close/>
              </template>
          </ButtonIcon>

          <slot name="content"></slot>

          <slot name="button" :submit="submit">
            <Button text="Save changes" @click="submit"/>
          </slot>
      </div>
    </div>
  </teleport>
</template>

<script>
import Button from '@/components/shared/buttons/Button';
import Close from '@/components/shared/icons/Close';
import ButtonIcon from '@/components/shared/buttons/ButtonIcon';

export default {
    name:"Modal",

    components:{
        Button,
        ButtonIcon,
        Close,
    },

    emits: ['click', 'submit'],

    data() {
      return {
        options:['Grade 1','Grade 2','Grade 3']
      };
    },

    methods: {
      submit() {
        this.$emit('submit');
      }
    },
}
</script>
<style scoped lang="scss">
@keyframes justOpacity{
    0% { opacity:0;}
    100% { opacity:1; }
}

@-webkit-keyframes justOpacity{
    0% { opacity:0; }
    100% { opacity:1; }
}

    .background{
        -webkit-animation: .5s ease 0s normal forwards 1 justOpacity;
        animation: .5s ease 0s normal forwards 1 justOpacity;
        position: absolute;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        background: rgba(233, 233, 233, 0.6);

        .modal{
             opacity: 0;
            -webkit-animation: 1s ease 0s normal forwards 1 fadein;
            animation: 1s ease 0s normal forwards 1 fadein;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 720px;
            min-height: 512px;
            background: #FFFFFF;
            border-radius: 24px;
            padding: 30px 0;

            .close{
                position: absolute;
                top: 37px;
                right: 37px;
            }
        }
    }
</style>
