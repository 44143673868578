<template>
  <ion-item class="ion-no-padding" lines="none">
    <ion-label v-if="label">
      {{ label }} <span v-if="required" class="asterisk"> *</span>
    </ion-label>
  </ion-item>
  <ion-item class="ion-no-padding" lines="none">
    <ion-datetime
      :value="modelValue ? $dayjs(modelValue).format('YYYY-MM-DD') : null"
      :display-format="displayFormat"
      :picker-format="pickerFormat"
      :placeholder="placeholder"
      :data-cy="cyName"
      @ionChange="
        $emit(
          'update:modelValue',
          $dayjs($event.target.value).format(format)
        )
      "
    ></ion-datetime>
  </ion-item>
</template>

<script>
  import { IonItem, IonLabel, IonDatetime } from '@ionic/vue';

  export default {
    components: {
      IonItem,
      IonLabel,
      IonDatetime,
    },

    props: {
      label: {
        type: String,
        default: null,
      },

      placeholder: {
        type: String,
        default: 'Select Date',
      },

      displayFormat: {
        type: String,
        default: 'MM/DD/YYYY',
      },

      pickerFormat: {
        type: String,
        default: 'MM DD YYYY',
      },

      required: {
        type: Boolean,
        default: false,
      },

      modalValue: {
        type: String,
        default: null,
      },

      cyName: {
        type: String,
        default: null,
      },
    },

    computed: {
      format() {
        return this.pickerFormat === 'HH:mm' ? 'HH:mm' : 'YYYY-MM-DD'
      }
    },

    emits: ['update:modelValue'],
  };
</script>

<style lang="scss" scoped>
  ion-label {
    margin: 0px;
    margin-bottom: 0px !important;
    margin-left: 10px !important;
    color: #212a4a;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    text-align: left;

    .asterisk {
      color: rgb(206, 28, 28);
      font-weight: 500;
    }
  }

  ion-datetime {
    --padding-end: 32px;
    --padding-start: 32px;
    display: flex;
    align-items: center;
    color: #212a4a;
    background: white;
    font-size: 14px;
    border-radius: 250px;
    text-align: left;
    width: 600px;
    height: 53px;
    border: 1px solid #e9e9e9;
  }
</style>
