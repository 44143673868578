<template>
  <ion-button
    expand="block"
    :class="[`btn-${variant}`]"
    :disabled="disabled"
    @click.stop="onClick"
    @mousedown="onMousedown"
  >
        <slot name="icon"></slot>
  </ion-button>
</template>

<script>

export default {
     name: 'ButtonIcon',

     props: {
      disabled: { type: Boolean, default: false },
      variant: { type: String, default: 'circle' },
    },

     methods: {
      onClick() {
        this.$emit('click');
      },

      onMousedown() {
        this.$emit('mousedown');
      },
    },
}
</script>

<style scoped lang="scss">
ion-button {
    width: 42px;
    height: 42px;
    --background:#F7F7F7;
    --border-style: 1px solid #E9E9E9;
    --border-radius: 100px;
    --box-shadow: none;
    --padding-top:12px;
    --padding-bottom:12px;
    --padding-start:12px;
    --padding-end:12px;
    text-transform: none;
  }
    .btn-circle{
        --border-radius: 100%;
    }
</style>