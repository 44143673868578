<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.65283 2L16.3466 17.3191" stroke="#17244E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.3467 2L1.65291 17.3191" stroke="#17244E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
    name:"Close"
}
</script>