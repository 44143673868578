<template>
  <div>
    <ion-item class="ion-no-padding" lines="none">
        <ion-label>
          {{ label }}<span v-if="required" class="asterisk"> *</span>
        </ion-label>
    </ion-item>
    <ion-item class="ion-no-padding" lines="none">
        <ion-select
          :placeholder="placeholder"
          :value="modelValue"
          :interface-options="{ header: label }"
          :data-cy="cyName"
          @ionChange="$emit('update:modelValue', $event.target.value)"
        >
          <ion-select-option
            v-for="option in filteredOptions"
            :key="option"
            :value="option.toLowerCase()"
          >
            {{option}}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <span v-show="error" class="errors">{{ error }}</span>
    </div>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption
} from '@ionic/vue';

export default {
    name:"SelectInput",

    components: {
      IonItem,
      IonLabel,
      IonSelect,
      IonSelectOption
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    cyName: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select One',
    },
    error: {
      type: String,
      default: null,
    },
    required: {
        type: Boolean,
        default: false,
      },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      filteredOptions: this.defaultValue
        ? this.options.filter(option => option !== this.defaultValue)
        : this.options,
    };
  },
}
</script>

<style scoped lang="scss">
    ion-label{
        margin-left: 10px !important;

        .asterisk {
          color: rgb(206, 28, 28);
          font-weight: 500;
        }
    }
     ion-select {
        --padding-end:32px;
        --padding-start:32px;
        font-size: 14px;
        color: #212a4a;
        background: white !important;
        border-radius: 250px;
        text-align: left;
        width: 600px;
        height: 53px;
        border: 1px solid #E9E9E9 !important;
  }

  .errors {
    color: rgb(206, 28, 28);
    font-size: 12px;
    display: inline-block;
    margin-top: 4px;
    margin-left: 12px;
  }
</style>
